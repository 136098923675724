$theme-colors: (
  "primary": #c6a15b,
  "danger": #ff4136,
);

$grid-breakpoints: (
  xs: 0,
  sm: 380px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$dark-color: #252525;
$note-background: #1e1e1e;
$note-text: #c3a451;
$medium-gray-color: #6c757d;
$light-gray: #f6f8fd;
$light-primary: #be995566;
$field-background: #F8F9FA;

@import "~bootstrap/scss/bootstrap.scss";

