@use "sass:map";
@import "custom-theme.scss";

// Mixins

@mixin button {
  // pull correct font from adobe cloud
  font-family: acumin-pro-condensed, sans-serif;
  font-weight: 600;
  font-style: normal;
  // ...
  background: #c6a15b;
  border-radius: 5px;
  outline: 3px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0.035em;
  padding: 6px 16px 8px;
  min-height: 60px;
  text-transform: uppercase;
  width: auto;
  text-decoration: none;
}

@mixin font-sm() {
  color: #4a4444;
  font-family: "AcuminProMedium";
  font-size: 13px;
  line-height: 1.15;
}

@mixin font-md() {
  color: #4a4444;
  font-family: "AcuminProMedium";
  font-size: 18px;
  line-height: 1.15;
}

@mixin font-heading-ital() {
  color: #565151;
  font-family: utopia-std-display, serif;
  font-weight: 400;
  font-style: italic;
  font-size: 28px;
}

// Style
html,
body {
  position: relative;
  height: 100%;
  width: 100%;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  /* required to prevent rogue scrollbars */
}

body {
  @include font-md;
  background-color: #ffffff !important;
  background-size: cover;
}

input[type="text"],
input[type="email"] {
  background-color: $field-background;
  min-height: 60px;
  font-size: 18px;
  padding-top: 10px;
  border-radius: 5px;
}

.disabled {
  opacity: 0.5;
}

.link {
  text-decoration: underline;
  cursor: pointer;
  color: map.get($theme-colors, "primary");
}

.page-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-check {
  padding: 4px;
}

.form-check-input {
  background-color: #fff !important;
  border-color: map.get($theme-colors, "primary") !important;
  border-width: 2px;
}

.form-check-input:checked {
  background-color: map.get($theme-colors, "primary") !important;
}

.cta-button {
  @include button;
}

.short-divider {
  width: 25%;
  height: 1px;
  opacity: 0.1;
  background-color: $dark-color;
}

.logo-container {
  width: 100%;
  max-height: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 1rem;
}

// TN1.0 LANDING (START) PAGE
.landing-page {
  .center-image {
    width: 90%;
    height: 360px;
    background-position: top left;
    background-size: cover;
  }
  ul {
    list-style: none;
    padding: 0px;
  }
  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 7px;
    padding-bottom: 4px;
  }
  .content {
    p {
      padding: 0;
      margin: 0;
    }
  }
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;

  .cta-button {
    transform: translateY(-35px);
  }
}

// TN2.0 CREATE ROOM PAGE
.create-room-page {
  .page-title {
    // font-family: "AcuminProMedium";
    // font-size: 0.9rem;
    width: 50%;
    text-transform: capitalize;
  }

  .cta-button {
    max-width: 7rem;
  }
  .room-form {
    width: 100%;
  }
  .attestation {
    background-color: $light-gray;
    border-radius: 5px;
    padding: 20px 15px 24px 23px;
    label {
      text-align: left !important;
      font-size: 13px;
    }
    input[type="checkbox"] {
      width: 30px !important;
      height: 30px !important;
    }
  }
}

.aroma-selection-page {
  .instructions {
    .title {
      font-family: "UtopiaStdItalic";
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
    .image {
      width: 90%;
    }
  }
}

// TN3.0 DASHBOARD aka wine selection page aka homepage

.wine-selection-page {
  .cta-button {
    width: 47.5%;
    letter-spacing: 0.01em;
    padding: 6px 10px 8px;
  }

  // .page-title {
  //   font-family: "UtopiaStdRegular";
  //   font-size: 1.2rem;
  //   color: blue !important;
  // }

  .options-container {
    .group-name {
      @include font-heading-ital();
    }

    .wine-options {
      .wine-option {
        position: relative;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        width: 100%;
        height: 90px;
        background-position: top left !important;
        background-size: cover !important;
        .overlay {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: #000;
          opacity: 0.3;
          z-index: 0;
          border-radius: 10px;
          pointer-events: none;
        }
        .react-star-rate {
          font-size: 1rem !important;
          margin-bottom: 5px;
          text-align: left;
          z-index: 1;
        }
        .react-star-rate__star {
          margin-right: 2px;
        }
        .title {
          @include font-md;
          margin-bottom: 0;
          text-align: left;
        }
        .title-stars-container {
          border-left: solid 1px #f4f4f4;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 10px;
          padding-right: 5px;
        }
        .background-image-cta {
          align-items: center;
          background-color: #f6f8fe;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: 50%;
          display: flex;
          height: 65px;
          justify-content: center;
          margin-right: 5px;
          width: 65px;
        }
        .view-cta {
          @include font-sm;
          align-items: center;
          background-color: #f6f8fe;
          border-radius: 50%;
          display: flex;
          height: 65px;
          justify-content: center;
          width: 65px;
          margin-right: 5px;
        }
        .note-cta {
          @include font-sm;
          align-items: center;
          display: flex;
          height: 80%;
          margin-top: 6px;
          flex-direction: column;
          justify-content: space-evenly;
          width: 65px;
          margin-right: 5px;

          svg {
            height: 20px;
            width: 20px;
          }
        }
        .wine-drawing {
          background-size: contain;
          background-repeat: no-repeat;
          height: 49px;
          transform: translateY(10px);
        }
        // .cta {
        //   background: rgba(37, 37, 37, 0.25);
        //   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
        //   color: white;
        //   font-family: "AcuminProCondMedium";
        //   font-size: 0.75rem;
        //   font-weight: 600;
        //   text-transform: uppercase;
        //   text-decoration: none;
        //   padding: 0.36rem 0 0.14rem 0;
        //   border-radius: 2px;
        //   z-index: 1;
        //   color: green;
        // }
      }
      .wine-option.light {
        color: #fff;
      }
      // .wine-option.white {
      //   background: linear-gradient(180deg, #ffe282 0%, #ddb01d 100%);
      // }
      // .wine-option.red {
      //   background: linear-gradient(180deg, #d05d58 0%, #862b2c 100%);
      // }
    }
  }
}

// DASHBOARD AND TASTING NOTE CREATION HEADER
.room-header {
  width: 100%;
  // padding-top: 10px;
  h5 {
    background-color: #e9e9e9;
    border-radius: 20px;
    color: #4a4444;
    // font-family: "AcuminProCondMedium";
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
    text-transform: uppercase;
    padding: 5px 17px 8px;
  }
  .container {
    @include font-sm;
    background-color: $light-gray;
    border-radius: 0px 0px 10px 10px;
    width: 90%;
    h5 {
      // font-size: 0.9rem;
      background: none;
    }
    .nav-button {
      display: block;
      font-size: 13px;
      border: 1px solid rgba(74, 68, 68, 0.5);
      filter: drop-shadow(0px -5px 12px rgba(0, 0, 0, 0.06));
      border-radius: 80px;
      // line-height: 0.75rem;
      text-decoration: none;
      color: #4a4444;
      letter-spacing: -0.02em;
    }
    .nav-button.active {
      background-color: #4a4444;
      color: #fff;
    }
  }
}
// ...

.room-header.white {
  background-color: #fada71;
  color: $dark-color;
}

.room-header.red {
  background-color: #d05d58;
  color: white;
}

.note-editor,
.memory-chooser,
.aroma,
.perception-chooser {
  border: 1px solid rgba(74, 68, 68, 0.1);
  border-radius: 5px;
}

.memory-chooser {
  .tappable-image {
    width: 305px;
    height: 315px;
    background-size: cover;
    background-position: top left;
  }
}

.note-editor {
  .notes-field {
    max-width: 90%;
    background-color: $field-background;
  }
}

.email-notes-modal {
}

.email-notes {
  font-size: 0.75rem;
  .small {
    font-size: 0.75rem;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  .disabled {
    opacity: 0.5;
  }
  .send-form {
    .form-check {
      column-gap: 0.5rem;
      .form-check-input {
        height: 32px;
        width: 32px;
        min-width: 32px;
        margin-left: 0 !important;
      }
    }
  }
}

.share-note-modal {
  border-top: 5px solid $note-text !important;
  .header {
    width: 100%;

    .close {
      color: $dark-color !important;
      font-size: 2em;
    }
  }
  .title {
    font-family: "UtopiaStdItalic";
    font-size: 2rem;
  }
  .subtitle {
    font-family: "AcuminProMedium";
    font-size: 0.8rem;
    text-align: center;
  }
}

.share-notes {
  .share-form-container {
    border: 1px solid map.get($theme-colors, "primary");
    .send-form {
      font-family: "AcuminProMedium";
      .small {
        font-size: 0.8rem;
      }
    }
    .cta-button {
      width: 100%;
    }
  }
}

.message-content {
  .title {
    font-family: "AcuminProMedium";
    font-size: 0.62rem;
    color: brown;
    display: none;
  }
  .icon {
    color: map.get($theme-colors, "primary");
    font-size: 5rem;
  }
  .message {
    @include font-heading-ital();
    padding: 0px 40px;
  }
  .cta-button {
    width: 100%;
  }
}

.tasting-summary {
  h1 {
    font-family: "UtopiaStdItalic";
    color: #fff;
  }
  .section {
    border-top: 3px solid #2f2f2f;
  }
  .matrix-container {
    position: relative;
    width: 300px;
    height: 330px;
    color: $light-gray;
    .top-left-label {
      position: absolute;
      left: 0;
      top: 0;
      font-size: 12px;
    }
    .top-right-label {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 12px;
    }
    .bottom-left-label {
      position: absolute;
      left: 0;
      bottom: 0;
      font-size: 12px;
    }
    .bottom-right-label {
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 12px;
    }
    .circle {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 1px solid #aaa;
      background-position: center center;
      background-size: cover;
    }
    .top-left {
      position: absolute;
      left: 15px;
      top: 18px;
    }
    .top-right {
      position: absolute;
      right: 15px;
      top: 18px;
    }
    .bottom-right {
      position: absolute;
      right: 15px;
      bottom: 18px;
    }
    .bottom-left {
      position: absolute;
      left: 15px;
      bottom: 18px;
    }
    .bar {
      position: absolute;
      background: $light-gray;
      opacity: 0.2;
      border-radius: 4px;
    }
    .bar-x {
      width: 4px;
      height: 100%;
    }
    .bar-y {
      width: 100%;
      height: 4px;
    }
    &__memory {
      width: 294px;
      height: 304px;
      background-position: top left;
      background-size: cover;
    }
  }
}

.tasting-note-page {
  background-color: $note-background !important;
  color: $note-text;
  .header {
    width: 100%;
    border-bottom: 3px solid #2f2f2f;
    span {
      font-family: "UtopiaStdRegular";
      font-size: 1rem;
    }
    .close-button {
      font-size: 1.5rem;
    }
  }
  .buy-button {
    line-height: 2.2rem;
  }
  .winemaker-notes {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    cursor: pointer;
    .winemaker-image {
      min-width: 130px;
      min-height: 130px;
      border-radius: 65px;
    }
    .copy {
      text-align: center;
    }
  }
  .wine-name {
    font-family: "AcuminProCondMedium";
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 3px solid #2f2f2f;
    padding-top: 1rem;
  }
  .react-star-rate {
    font-size: 1.5rem;
    color: $note-text !important;
    &__star {
      color: $note-text !important;
    }
  }
  .note {
    font-family: "UtopiaStdItalic";
    font-size: 2rem;
  }
  .winemaker-notes {
    width: 100%;
    border-top: 3px solid #2f2f2f;
  }
  .join {
    font-family: "UtopiaStdRegular";
    font-size: 1rem;
    color: $light-gray;
    .join-text {
      max-width: 37%;
      line-height: 120%;
    }
  }
}

.winemaker-tasting-note-page {
  background-color: $note-background !important;
  color: $note-text;
  .header {
    width: 100%;
    border-bottom: 3px solid #2f2f2f;
    span {
      font-family: "UtopiaStdRegular";
      font-size: 1rem;
    }
    .close-button {
      font-size: 1.5rem;
    }
  }
  .about-winemaker {
    .winemaker-image {
      min-width: 130px;
      min-height: 130px;
      border-radius: 65px;
    }
    .copy {
      text-align: center;
    }
  }
  .wine-name {
    font-family: "AcuminProCondMedium";
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  .section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-top: 3px solid #2f2f2f;
    padding-top: 1rem;
  }
  .react-star-rate {
    font-size: 1.5rem;
    color: $note-text !important;
    &__star {
      color: $note-text !important;
    }
  }
  .note {
    width: 100%;
    @include font_md;
    color: $note-text !important;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .winemaker-notes {
    width: 100%;
    border-top: 3px solid #2f2f2f;
  }
  .join {
    font-family: "UtopiaStdRegular";
    font-size: 1rem;
    color: $light-gray;
    .join-text {
      max-width: 37%;
      line-height: 120%;
    }
  }
}

.perception-chooser {
  width: 350px;
  height: 350px;
  border: 1px sollid #000;
  .small {
    font-size: 0.6rem;
  }
  .chooser {
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto auto;
    .top-left-label {
      position: absolute;
      left: 5px;
      top: 0;
      font-size: 12px;
    }
    .top-right-label {
      position: absolute;
      right: 5px;
      top: 0;
      font-size: 12px;
    }
    .bottom-left-label {
      position: absolute;
      left: 5px;
      bottom: 0;
      font-size: 12px;
    }
    .bottom-right-label {
      position: absolute;
      right: 5px;
      bottom: 0;
      font-size: 12px;
    }
    .circle {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 1px solid #aaa;
      background-position: center center;
      background-size: cover;
    }
    .top-left {
      position: absolute;
      left: 22px;
      top: 20px;
    }
    .top-right {
      position: absolute;
      right: 22px;
      top: 20px;
    }
    .bottom-right {
      position: absolute;
      right: 22px;
      bottom: 20px;
    }
    .bottom-left {
      position: absolute;
      left: 22px;
      bottom: 20px;
    }
    .bar {
      background: $light-gray;
      opacity: 0.7;
      border-radius: 5px;
    }
    .bar-x {
      width: 4px;
      height: 100%;
    }
    .bar-y {
      width: 100%;
      height: 4px;
    }
    .handle {
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      background: #be9955;
      border: 3px solid rgba(0, 0, 0, 0.05);
      box-shadow: 0px 22px 66px rgba(0, 0, 0, 0.175);
      color: #fff;
      font-size: 1.5rem;
    }
  }
}

// this section needs to get DRYd up
.aroma-selection-page,
.taste-selection-page,
.memory-selection-page {
  .title {
    display: none;
  }
  .subtitle {
    @include font-heading-ital();
    font-size: 29px;
    margin-top: 48px;
    padding: 0px 20px;
  }
}

.aroma-selection-page {
  .instructions .title {
    @include font-heading-ital();
    display: initial;
    padding-top: 36px;
    font-size: 29px;
  }
}

.add-note-page {
  .title {
    @include font-heading-ital();
    margin-top: 48px;
  }
  .subtitle {
    @include font-heading-ital();
    font-size: 29px;
    color: green;
  }
}

.email-notes {
  .title {
    @include font-heading-ital();
  }
  .subtitle {
    @include font-heading-ital();
    font-size: 29px;
    padding: 0px 30px;
  }
}
