@font-face {
  font-family: "AcuminProCondMedium";
  src: local("AcuminProCondMedium"),
    url("./assets/fonts/Acumin-Pro-Cond-Medium.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "AcuminProMedium";
  src: local("AcuminProMedium"),
    url("./assets/fonts/Acumin-Pro-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "UtopiaStdItalic";
  src: local("UtopiaStdItalic"),
    url("./assets/fonts/UtopiaStd-Italic.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "UtopiaStdRegular";
  src: local("UtopiaStdRegular"),
    url("./assets/fonts/UtopiaStd-Regular.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "AJensonProCapt";
  src: local("AJensonProCapt"),
    url("./assets/fonts/AJensonPro-Capt.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "AcuminProSemiCondMed";
  src: local("AcuminProSemiCondMed"),
    url("./assets/fonts/Acumin Pro SemiCond Medium.otf") format("opentype");
  font-weight: 300;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
